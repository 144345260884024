import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import pt from "vuetify/lib/locale/pt";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#811B5A",
        success: "#4b8178",
        secondary: "#ffe4fa",
        accent: "#724498",
        error: "#b33859",
        info: "#0091df",
        warning: "#f3904f",
      },
    },
  },
  lang: {
    locales: { pt },
  },
});
